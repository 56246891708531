<template>
  <div class="page" style="width: 100%;height: 100%;overflow: hidden;">
    <supplyType></supplyType>
    <div class="Center" style="padding:10px 10px;box-sizing: border-box;width: 100%;height:100%;overflow:auto;">
      <div class="swiper">
        <el-carousel height="460px">
          <el-carousel-item v-for="(item, i) in bannerList" :key="i">
            <img :src="item.param1" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="homeList" v-for="(item, index) in firstClassList.itemList" :key="index">
        <div class="box-hd">
          <h2 class="title">{{ item.name }}</h2>
          <div class="more" @click="getMore">查看更多<i class="el-icon-arrow-right"></i></div>
        </div>
        <ul class="homeList_I">
          <li class="brick-item brick-item-m brick-item-m-2" v-for="(itam, i) in item.goodList" :key="i"
            @click="getDetail(itam)">
            <div class="figure figure-img"><img width="160" height="160" alt="Redmi K70 至尊版" :src="itam.cover"
                lazy="loaded"></div>
            <h3 class="title">
              {{ itam.title }}
            </h3>
            <p class="price"><span class="num">{{ itam.showPrice }}</span>元<span>起</span></p>
          </li>
        </ul>
      </div>
      <div>
        <div class="comView">
          <div class="comL">
            <div>关住我们</div>
            <div class="comImg">
              <img src="https://www.hscwhn.com/static/img/wechat.png" alt="">
              <img src="https://www.hscwhn.com/static/img/dy.png" alt="">
            </div>
          </div>
          <div class="comL">
            <div>服务热线</div>
            <div>
              <div>王璇电话</div>
              <div>周一至周五8.30-18.30</div>
            </div>
          </div>

        </div>
        <div class="comBox">
          <div>全网低价</div>
          <div>花生品质保证</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import supplyType from "./supplyType.vue";
// import supplyfoot from "./supplyfoot.vue";
export default {
  components: {
    supplyType,
    // supplyfoot
  },
  data() {
    return {
      firstClassList: [],
      secondClassList: [],
      firstClass: '',
      secondClass: '',
      tableData: [],
      bannerList: []
    }
  },
  mounted() {
    this.getBreed()
    this.getBanner()
  },
  methods: {
    // 跳转详情
    getDetail(itam) {
      let query = {
        id: itam.id
      }
      this.$router.push({ path: 'supplyDetail', query })
    },
    // 获取轮播图
    getBanner() {
      let params = {
        code: 'supplybanner',
        CompanyId: '668638e374396787c0371b60'
      }
      this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
        if (res.code === 0) {
          console.log(res, '轮播图');
          this.bannerList = res.data.itemList
        }
      })
    },
    // 获取类目
    getBreed() {
      let params = {
        code: 'supplyproducttype',
        CompanyId: '668638e374396787c0371b60'
      }
      this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
        if (res.code === 0) {
          res.data.itemList.map((item) => {
            let params = {
              pageIndex: 1,
              pageSize: 10,
              keyword: '',
              petType: '',
              brand: '',
              firstCategory: item.id,
              secondCategory: ''
            }
            this.$http.post("/product/mall/goods/list", params).then(resp => {
              if (resp.code === 0) {
                item.goodList = resp.data.items
                console.log(item, '列表');
              }
            })
            setTimeout(() => {
              this.firstClassList = res.data;
            }, 1000)
            console.log(this.firstClassList, 'this.firstClassList');
          })

        }
      })
    },
    // 产看更多
    getMore() {
      this.$router.push({ path: 'supplyTypeList' })
    }
  }
}
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: #475669;
  }



  .swiper {
    width: 1226px;
    height: 460px;
    margin: 0 auto;

    .el-carousel__item h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
      background-color: #d3dce6;
    }

    ::v-deep.el-carousel__indicator--horizontal .el-carousel__button {
      width: 10px;
      height: 10px;
      background: #686f6f;
      border: 1px solid #727979;
      border-radius: 50%;
      opacity: 0.3;
    }

    ::v-deep.el-carousel__indicator--horizontal.is-active .el-carousel__button {
      width: 10px;
      height: 10px;
      background: rgba(0, 0, 0, .4);
      border-radius: 50%;
      opacity: 1;
      border-color: hsla(0, 0%, 100%, .3);
    }

  }

  .homeList {
    width: 1226px;
    margin: 0 auto;

    .box-hd {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .more .more-link {
      color: #424242;
    }

    .more :hover {
      color: #ff5f00;
    }

    .more-link :hover {
      width: 12px;
      height: 12px;
      padding: 4px;
      margin-left: 8px;
      border-radius: 16px;
      font-size: 12px;
      line-height: 12px;
      color: #ff5f00;
      vertical-align: 1px;
      -webkit-transition: all .4s;
      transition: all .4s;
    }

    [class*=iconfont] {
      font-family: iconfont !important;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -webkit-text-stroke-width: .2px;
      -moz-osx-font-smoothing: grayscale;
    }

    .iconfont-arrow-right-big:before {
      content: "\E623";
    }

    .homeList_I {
      width: 1226px;
      display: flex;
      flex-wrap: wrap;
    }

    .brick-item {
      width: 205.2px;
      margin: 20px;
      margin-bottom: 14px;
      background: #fff;
      text-align: center;
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
    }

    .brick-item:hover {
      z-index: 2;
      -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
      box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
      -webkit-transform: translate3d(0, -2px, 0);
      transform: translate3d(0, -2px, 0)
    }

    .price {
      text-align: center;
      color: #ff6700;
    }
  }

  .comView {
    width: 100%;
    height: 300px;
    background-color: #9a9898;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .comL {
      text-align: center;
      line-height: 40px;

      .comImg {
        img {
          margin: 10px 20px;
        }

      }
    }
  }

  .comBox {
    width: 100%;
    height: 300px;
    background-color: #f8f5f5;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }


}
</style>
